import { mapGetters } from "vuex";
import * as types from "./../../store/types";
import $formatNumber from "./../../mixins/formatNumber";
import { ASSETS_URL } from "../../services/base";

export default {
    name: "dashboard-wallet",
    components: {},
    props: [],
    mixins: [$formatNumber],
    data() {
        return {
            baseURL: ASSETS_URL,
            statuses: "",
        };
    },
    computed: {
        ...mapGetters({
            walletIsLoaded: types.GET_IS_WALLET_LOADED,
            wallets: types.GET_WALLET,
            currencies: types.GET_CURRENCIES,
        }),
    },
    mounted() {},
    methods: {},
};
